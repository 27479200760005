import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { MdModeEdit, MdHistory } from "react-icons/md";
import Modal from "react-modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MdClose } from "react-icons/md";
import config from "../../../../config";
Modal.setAppElement("body");
export default class NoteItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditNote: false,
      showEditMedia: false,
      showActivities: true,
      showNotes: true,
      editedNoteId: "",
      addedNote: "",
      editedNote: "",
      mediaTypeWarning: "",
      showDueDateChangeModal: false,
      showLogs: false,
    };
    this.handleBtnClick = this.handleBtnClick.bind(this);
  }

  handleBtnClick = () => {
    if (this.props.activity.list_media?.length > 0) {
      const imgArray = this.props.activity.media?.split("/");
      const imgName = imgArray?.length > 0 ? imgArray[imgArray.length - 1] : imgArray;
      this.setState({
        showEditMedia: true,
        mediaNotes: this.props.activity.media,
        editedNoteId: this.props.activity.id,
        editedNote: imgName,
      });
    } else {
      this.setState({
        showEditNote: true,
        editedNote: this.props.activity.detail,
        editedNoteId: this.props.activity.id,
      });
    }
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleEditNote = () => {
    let formData = new FormData();
    formData.append("note", this.state.editedNote);
    this.addNote(formData);
  };
  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "video/mp4", "video/webm", "video/quicktime"];

    if (!allowedTypes.includes(file.type)) {
      alert("Only JPG, JPEG, PNG, WEBM , MOV and MP4 files are allowed.");
      this.setState({ allowUpload: false });
    } else {
      this.setState({ allowUpload: true });
    }
    const media = document.getElementById("uploadNoteMedia").files[0];
    this.setState({
      mediaNotes: media,
      dueDateType: "media",
      fileName: file.name,
    });
  };

  handleEditMedia = () => {
    // e.preventDefault();
    if (this.state.allowUpload && this.state.mediaNotes) {
      this.setState({ loading: true, showAddMedia: false });
      // const mediaNotes = document.getElementById("uploadNoteMedia").files[0];
      let formData = new FormData();
      formData.append("media", this.state.mediaNotes);
      formData.append("note", this.state.mediaNotes.type);
      this.addNote(formData);
    } else {
      // alert("Only JPG, JPEG, PNG, WEBM, MOV and MP4  files are allowed.");
      this.setState({ loading: false, showAddMedia: true });
    }
  };

  handleEdit = (edit) => {
    console.log(edit);
    this.setState({ editedNote: edit });
  };

  addNote(formData) {
    // const userInfo = localStorage.getItem("lgn") || utilityFunctions.getCookie("userInfo");
    const token = this.props?.userInfo?.access_token;
    let options = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    };

    const url = this.state.editedNoteId
      ? `${config.backendServer}ticket/${this.props.ticketId}/note/${this.state.editedNoteId}/`
      : `${config.backendServer}ticket/${this.props.ticketId}/note/`;
    axios
      .put(url, formData, options)
      .then(() => {
        this.setState({
          addedNote: "",
          editedNoteId: "",
          editedNote: null,
          showAddNote: false,
          showAddMedia: false,
          disableBtnNote: false,
          isLoading: false,
          showEditNote: false,
          selectedLogs: [],
        });
        this.props.retrieveWarranty();
      })
      .catch(() => {
        // Handle the error or show an error message
        // if(error) alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
        this.setState({ showEditNote: false });
      });
    this.props.retrieveWarranty();
  }

  render() {
    return (
      <div>
        <div key={this.props.activity.id} className="card mb-2" data-testid={`activity-${this.props.activity.id}`}>
          <div style={{ padding: "3px", margin: "1px" }} className={"card-note-title row  rounded-top"}>
            <div className="col-8">{this.props.activity.subject}</div>
            {/* <div className="col-8">{this.props.userInfo.user.name}</div> */}

            <div className="col-4 text-end">
              {this.props.activity.change_log?.length > 0 && (
                <button
                  data-testid={`editNoteButton-${this.props.activity.id}`}
                  type="button"
                  className="k-btn k-btn-outline-primary k-btn-xs "
                  onClick={() =>
                    this.setState({
                      showLogs: true,
                      selectedLogs: this.props.activity.change_log,
                    })
                  }
                >
                  <MdHistory />
                  {" Logs"}
                </button>
              )}
              {this.props.isMyNote && (
                <button
                  data-testid={`editNoteButton-${this.props.activity.id}`}
                  type="button"
                  className="k-btn k-btn-outline-primary k-btn-xs ml-1"
                  onClick={() => this.handleBtnClick()}
                >
                  <MdModeEdit />
                  {" Edit"}
                </button>
              )}
            </div>
          </div>

          <div className="card-body">
            <div className="row d-flex justify-content-left">
              {this.props.activity.list_media?.length > 0 ? (
                this.props.activity.list_media.map((media, idx) => {
                  const className =
                    this.props.activity.list_media?.length > 1
                      ? "p-2 col-6 border border-light d-flex  justify-content-left "
                      : "p-2 border border-light d-flex  justify-content-left ";
                  const isPicture =
                    media.media.toLowerCase().includes(".jpg") ||
                    media.media.toLowerCase().includes(".png") ||
                    media.media.toLowerCase().includes(".jpeg");
                  const isVideo =
                    media.media.toLowerCase().includes(".mp4") ||
                    media.media.toLowerCase().includes(".mov") ||
                    media.media.toLowerCase().includes(".webm");
                  if (isPicture)
                    return (
                      <div key={idx} className={className}>
                        <img width="auto" height="auto" src={media.media} alt={media} key={media} />
                      </div>
                    );
                  else if (isVideo)
                    return (
                      <div key={idx} className={className}>
                        <video width="100%" height="auto" controls data-testid={`video-${this.props.activity.id}`} key={idx}>
                          <source src={media.media} type="video/mp4" />
                          <source src={media.media} type="video/webm" />
                          <source src={media.media} type="video/quicktime" />
                          <source src={media.media} type="video/x-quicktime" />
                        </video>
                      </div>
                    );
                })
              ) : (
                <div dangerouslySetInnerHTML={{ __html: this.props.activity.detail }} />
                // <div className="card-text">{this.props.activity.detail}</div>
              )}
            </div>
          </div>
          <div className="card-footer">
            <small>
              <div className="d-flex justify-content-between">
                <span>{moment(this.props.activity.modified).format("DD MMMM YYYY HH:mm")}</span>
                {/* <span>{this.props.activity.created_by && `by ${this.props.activity.created_by}`}</span> */}
              </div>
            </small>
          </div>
        </div>
        <Modal
          isOpen={this.state.showEditNote}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"input-modal w-25 h-50 "}
        >
          <div className="card  h-100">
            <button data-testid={"closeModal"} onClick={() => this.setState({ showEditNote: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className={"card-header"}>{`${this.state.showEditNote ? "Edit" : "Add"} Note`}</div>
            <div className="card-body overflow-auto">
              <ReactQuill
                value={this.state.addedNote || this.state.editedNote || ""}
                onChange={(val) => this.setState({ editedNote: val })}
                id={"editedNote"}
              />
            </div>
            <div className="card-footer">
              <button
                data-testid={"saveNote"}
                disabled={this.state.disableBtnNote}
                type="button"
                className={"btn btn-outline-primary mr-3"}
                onClick={this.handleEditNote}
              >
                Save
              </button>
              <button
                data-testid={"cancelNote"}
                type={"button"}
                className={"btn btn-outline-secondary"}
                onClick={() => this.setState({ showEditNote: false, addedNote: "" })}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showEditMedia}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"input-modal w-25"}
        >
          <div className="card">
            <button data-testid={"closeModal"} onClick={() => this.setState({ showEditMedia: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">{`${this.state.editedNote ? "Edit" : "Add"} Picture / Video`}</div>
            <div className={"card-body"}>
              <div className="border p-0 " style={{ borderRadius: "8px" }}>
                <label data-testid="uploadMedia" htmlFor="uploadNoteMedia" className="custom-file-upload-outline half-radius">
                  Choose File
                </label>
                <span>{`  ${this.state.editedNote ? this.state.editedNote : "No file choosen"}`}</span>
                <input
                  accept="image/jpeg, image/png, video/mp4, video/webm, video/quicktime"
                  type={"file"}
                  id={"uploadNoteMedia"}
                  data-testid="uploadMediaInput"
                  // className="custom-file-upload"
                  required={true}
                  onChange={this.handleFileUpload}
                />
              </div>

              <div className="text-danger">
                <small>{this.state.mediaTypeWarning}</small>
              </div>
            </div>
            <div className="card-footer">
              <button
                data-testid={"saveNoteMedia"}
                disabled={this.state.disableBtnNote}
                type="submit"
                className={"btn btn-outline-primary mr-3"}
                onClick={() => this.handleEditMedia()}
              >
                Save
              </button>
              <button
                data-testid={"cancelMedia"}
                type={"button"}
                className={"btn btn-outline-secondary"}
                onClick={() => this.setState({ showEditMedia: false, addedNote: "" })}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showLogs} className={"input-modal w-25"}>
          <div className="card">
            <button data-testid={"closeModal"} onClick={() => this.setState({ showLogs: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">Note logs</div>
            <div className="card-body" style={{ maxHeight: "600px", overflowY: "auto" }}>
              <b>Current note: </b>
              <div className="card-text mb-2 border bg-light p-2 rounded">
                <div dangerouslySetInnerHTML={{ __html: this.props.activity.detail }} />
                <small>{moment(this.props.activity.modified).format("DD-MM-yyyy HH:mm")}</small>
              </div>
              <div className="card-text">
                <b>Previous notes: </b>
              </div>
              {this.props.activity.change_log.length > 0 && (
                <ul className="list-group list-group-flush overflow-auto h-50 ">
                  {this.props.activity.change_log
                    .sort((a, b) => b.id - a.id)
                    .map((log) => {
                      return (
                        <li className="list-group-item mb-2 border bg-light p-2 rounded" key={log.id}>
                          <div dangerouslySetInnerHTML={{ __html: log.old_value }} />
                          <small>{moment(log.created).format("DD-MM-yyyy HH:mm")}</small>
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
